@tailwind base;
@tailwind components;
@tailwind utilities;

.background {
  background-image: radial-gradient(#ffffff 2.5px, transparent 2.5px), radial-gradient(#ffffff 2.5px, transparent 2.5px);
  background-size: 36px 36px;
  background-position: 0 0, 18px 18px;
  background-color: #b1d2ff;
}

.spinner {
   margin: 40px;
   width: 11.2px;
   height: 11.2px;
   animation: spinner-z355kx 1s infinite linear;
   border-radius: 11.2px;
   box-shadow: 28px 0px 0 0 #fff, 17.4px 21.8px 0 0 #fff, -6.2px 27.2px 0 0 #fff, -25.2px 12px 0 0 #fff, -25.2px -12px 0 0 #fff, -6.2px -27.2px 0 0 #fff, 17.4px -21.8px 0 0 #fff;
}

@keyframes spinner-z355kx {
   to {
      transform: rotate(360deg);
   }
}